import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { useScrollContext } from '../../context/ScrollContext/ScrollContextProvider';
import styles from './Navbar.module.css';
import PageTransition from '../pageTransition/PageTransition';

export default function Navbar() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { handleScroll, aboutUsRef, ecosystemRef, mainRef } =
    useScrollContext();

  const handleClick = async (elementRef, scrollToTop = false) => {
    const navigateToHomePage = async () => {
      if (location.pathname !== '/') {
        await navigate('/');
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    };

    const handleScrollAction = () => {
      if (scrollToTop) {
        window.scrollTo(0, 0);
      } else {
        handleScroll(elementRef);
      }
    };

    await navigateToHomePage();
    handleScrollAction();
  };

  return (
    <>
      <div className={styles.navbarContainer}>
        {/* Logo */}
        <div className={styles.navbarLogoContainer}>
          <img
            className={styles.navbarLogo}
            src='/fusoft-logo.svg'
            alt='FuSoft logo'
            onClick={() => handleClick(mainRef, true)}
          />
        </div>

        {/* Menu */}
        <div className={styles.navbarLinks}>
          {/* #10565F */}
          <span
            className={styles.linkItemText}
            onClick={() => handleClick(aboutUsRef)}
          >
            Về Fusoft
          </span>
          <span
            className={styles.linkItemText}
            onClick={() => handleClick(ecosystemRef)}
          >
            Sản phẩm
          </span>
          <span
            className={styles.linkItemText}
            onClick={() => navigate('/lien-he')}
          >
            Liên hệ
          </span>
          <span
            className={classNames([styles.linkItemText, styles.mobileMenuIcon])}
            style={{ width: '30px', height: '30px' }}
          >
            {!mobileMenu ? (
              <img
                src='/mobile-menu.png'
                alt=''
                style={{
                  width: '30px',
                  height: '30px',
                  animationDuration: 'initial',
                }}
                onClick={() => setMobileMenu(true)}
              />
            ) : (
              <img
                src='close.png'
                alt=''
                style={{
                  width: '30px',
                  height: '30px',
                  animationDuration: 'initial',
                }}
                onClick={() => setMobileMenu(false)}
              />
            )}
          </span>
          {/*********  Start - Đoạn này hide (anh Ben kêu) **********/}

          {/* <span
          className={styles.linkItemText}
          onClick={() => alert('Direct to news page')}
        >
          Tin tức
        </span>
        <span className={styles.searchIconContainer}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='5 5 14 14'
            className={styles.navbarSearchIcon}
          >
            <path
              fill='currentColor'
              d='m15.683 14.6 3.265 3.265a.2.2 0 0 1 0 .282l-.8.801a.2.2 0 0 1-.283 0l-3.266-3.265a5.961 5.961 0 1 1 1.084-1.084zm-4.727 1.233a4.877 4.877 0 1 0 0-9.754 4.877 4.877 0 0 0 0 9.754z'
            ></path>
          </svg>
        </span>
        <span
          className={styles.loginCtaContainer}
          onClick={() => alert('Login function logic goes here')}
        >
          <div className={styles.filledIconContainer}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className={styles.loginIcon}
            >
              <path
                fillRule='evenodd'
                d='M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z'
                clipRule='evenodd'
              />
            </svg>
          </div>
          <span className={styles.loginCta}>Đăng nhập</span>
        </span> */}

          {/********** End - Đoạn này hide (anh Ben kêu) **********/}
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenu && (
        <PageTransition>
          <div className={styles.mobileMenu}>
            <span
              className={styles.mobileMenuLink}
              onClick={async () => {
                await setMobileMenu(false);
                handleClick(aboutUsRef);
              }}
            >
              Về Fusoft
            </span>
            <span
              className={styles.mobileMenuLink}
              onClick={async () => {
                await setMobileMenu(false);
                handleClick(ecosystemRef);
              }}
            >
              Sản phẩm
            </span>
            <span
              className={styles.mobileMenuLink}
              onClick={async () => {
                await setMobileMenu(false);
                navigate('/lien-he');
              }}
            >
              Liên hệ
            </span>
          </div>
        </PageTransition>
      )}
    </>
  );
}
