import React from 'react';

import styles from './Home.module.css';
import {
  AboutUs,
  Ecosystem,
  Feedbacks,
  Intro,
  ScrollArrow,
  WhyFusoft,
  PageTransition,
} from '../../components';

export default function Home() {
  document.title =
    'CÔNG TY CỔ PHẦN CÔNG NGHỆ FUS | Giải pháp phần mềm cho phòng game';

  return (
    <PageTransition>
      <div className={styles.homePageContainer}>
        <Intro />
        <ScrollArrow />
        <AboutUs />
        <Ecosystem />
        <WhyFusoft />
        <Feedbacks />
      </div>
    </PageTransition>
  );
}
