import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

import styles from './Popup.module.css';
import { Link } from 'react-router-dom';

export default function Popup() {
  // TODO: Call API to get the popup here
  const [popup, setPopup] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    async function getPopup() {
      try {
        const response = await axios.get('/action/popup');
        const data = await response.data;

        const toDate = new Date(data.todate);
        const currentDate = new Date();

        if (currentDate > toDate) {
          setShowPopup(false);
          return;
        } else {
          setPopup(data);
          setShowPopup(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    setTimeout(() => {
      getPopup();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showPopup && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}
          className={styles.popup}
        >
          <div className={styles.content}>
            <button
              className={styles.close}
              onClick={() => setShowPopup(false)}
            >
              X
            </button>
            {popup?.url ? (
              <Link to={popup.url} style={{ cursor: 'pointer' }}>
                <img src={popup.img} className={styles.img} alt='' />
              </Link>
            ) : (
              <img src={popup.img} className={styles.img} alt='' />
            )}
          </div>
        </motion.div>
      )}
    </>
  );
}
