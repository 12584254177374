import React from 'react';

import styles from './ErrorMsg.module.css';

export default function ErrorMsg({ msg }) {
  return (
    <p role='alert' className={styles.errorMsg}>
      <img src='/warning.png' alt='' />
      <span className={styles.errorInfo}>{msg}</span>
    </p>
  );
}
