export const importantInfo = [
  {
    id: 1,
    image: '/phong_may.png',
    number: '4000+',
    text: 'Phòng máy',
  },
  {
    id: 2,
    image: '/may_tram.png',
    number: '120.000+',
    text: 'Máy trạm',
  },
  {
    id: 3,
    image: '/user.png',
    number: '400.000+',
    text: 'Người dùng mỗi ngày',
  },
  {
    id: 4,
    image: '/location.png',
    number: '63',
    text: 'Tỉnh thành cả nước',
  },
];