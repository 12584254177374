import React from 'react';
import ReactDOM from 'react-dom/client';
import { AnimatePresence } from 'framer-motion';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';

import App from './App';
import './index.css';
import { AppContextProvider } from './context/AppContextProvider';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <AnimatePresence>
        <AppContextProvider>
          <Toaster position='top-right' />
          <App />
        </AppContextProvider>
      </AnimatePresence>
    </BrowserRouter>
  </React.StrictMode>
);
