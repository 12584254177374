export const products = [
  {
    id: 1,
    image: '/fastup.jpg',
    name: 'Phần mềm cập nhật game',
    desc: 'Phần mềm chuyên dụng cho việc cập nhật game nhanh chóng - đơn giản',
    linkToOutside: false,
    link: '/lien-he',
  },
  {
    id: 2,
    image: '/ffood.jpg',
    name: 'Phần mềm quản lý dịch vụ',
    desc: 'Phần mềm quản lý dịch vụ, bán hàng tự động chuyên nghiệp cho phòng game',
    linkToOutside: true,
    link: 'https://ffood.com.vn/',
  },
  {
    id: 3,
    image: '/fnet.jpg',
    name: 'Phần mềm tính tiền',
    desc: 'Phần mềm quản lý tính tiền hội viên tự động cho phòng game',
    linkToOutside: false,
    link: '/lien-he',
  },
  {
    id: 4,
    image: '/fumeli.jpg',
    name: 'Phần mềm cập nhật game',
    desc: 'Cổng dịch vụ giải trí cho hội viên phòng game duy nhất tại Việt Nam',
    linkToOutside: false,
    link: '/lien-he',
  },
];