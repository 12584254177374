import React from "react";
import classNames from "classnames";

import styles from "./AboutUs.module.css";
import { useScrollContext } from "../../context/ScrollContext/ScrollContextProvider";
import { importantInfo } from "../../utils/importantInfo";

export default function AboutUs() {
  const { aboutUsRef } = useScrollContext();

  // * Nội dung phần "Về Fusoft"
  return (
    <section className={styles.aboutUsContainer} ref={aboutUsRef}>
      {/* Logo (3 cái server) + Thông tin tổng quát về công ty */}
      <div className={classNames(styles.aboutUsContent)}>
        {/* Logo */}
        <div className={styles.aboutUsContentImage}>
          <img src='/fus-service.png' alt='' />
        </div>

        {/* Thông tin tổng quát về công ty */}
        <div className={styles.aboutUsContentText}>
          <h2 className={styles.aboutUsTitle}>Về Fusoft</h2>
          <div className={styles.aboutUsTextContainer}>
            <p className={styles.paraTitle}>Fusoft là gì?</p>
            <p className={styles.paraContent}>
              Fusoft: Chúng tôi cung cấp giải pháp phần mềm quản lý, vận hành và
              chăm sóc phòng máy internet, icafe cyber game
            </p>
          </div>
          <div className={styles.aboutUsTextContainer}>
            <p className={styles.paraTitle}>Ra mắt từ bao giờ?</p>
            <p className={styles.paraContent}>
              Sau nhiều năm nghiên cứu và phát triển sản phẩm, Fusoft cùng đội
              ngũ kỹ thuật viên dày dặn kinh nghiệm tự hào là đơn vị cung cấp hệ
              sinh thái phần mềm, giải pháp giúp tối ưu hóa những hạn chế trong
              quản lý phòng máy internet, icafe cyber game tại Việt Nam
            </p>
            <p className={styles.paraContent}>
              Với mục tiêu mang lại cho khách hàng những giải pháp, sản phẩm
              đáng tin cậy và hiệu quả cao nhất, Fusoft ngày càng được rất nhiều
              khách hàng tin tưởng và lựa chọn để phục vụ cho việc kinh doanh
            </p>
          </div>
          <div className={styles.aboutUsTextContainer}>
            <p className={styles.paraTitle}>
              Ngành nghề dịch vụ nào Fusoft đang cung cấp cho khách hàng?
            </p>
            <p className={styles.paraContent}>
              Fusoft cung cấp giải pháp phần mềm quản lý vận hành toàn diện cho
              phòng máy: phần mềm quản lý phòng game, phần mềm cập nhật game tự
              động, giải pháp phòng game, dịch vụ phòng game...
            </p>
            <p className={styles.paraContent}>
              Dịch vụ chăm sóc, hỗ trợ 24/7 cho tất cả các phòng máy trên toàn
              quốc.
            </p>
          </div>
        </div>
      </div>

      {/* Phần text nằm giữa bên dưới + 4 Thông tin quan trọng */}
      <div className={styles.companyOverviewContainer}>
        {/* Phần text nằm giữa bên dưới */}
        <div className={styles.companyOverviewTextContainer}>
          <h2 className={styles.companyOverviewTitle}>
            Cung cấp sản phẩm & giải pháp phần mềm cho thị trường Icafe, Cyber
            game Việt Nam
          </h2>
          <p className={styles.companyOverviewText}>
            Với đội ngũ kỹ sư phần mềm giàu kinh nghiệm và hơn 200+ Kỹ thuật
            viên đối tác chuyên nghiệp hỗ trợ phòng máy 24/7, chúng tôi đáp ứng
            được những gì?
          </p>
        </div>

        {/* 4 Thông tin quan trọng */}
        <div className={styles.companyOverviewReportContainer}>
          <div className={styles.companyOverviewReport}>
            {importantInfo.map((item) => (
              <div key={item.id} className={styles.reportItem}>
                <img
                  className={styles.reportItemImg}
                  src={item.image}
                  alt=''
                />
                <span className={styles.reportItemNumber}>{item.number}</span>
                <span className={styles.reportItemText}>{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Đối tác & Khách hàng */}
      <div className={styles.partnerAndCustomer}>
        <h2 className={styles.partnerAndCustomerTitle}>Đối tác & Khách hàng</h2>
        <div className={styles.partnerAndCustomerImgContainer}>
          <img src='/partner_customer.png' alt='' />
        </div>
      </div>
    </section>
  );
}
