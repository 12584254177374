import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './Intro.module.css';
import { useScrollContext } from '../../context/ScrollContext/ScrollContextProvider';

export default function Intro() {
  const { mainRef } = useScrollContext();
  const navigate = useNavigate();

  //* Mới vào trang ("DÙNG THỬ MIỄN PHÍ")
  return (
    <section className={styles.introContainer} ref={mainRef}>
      <div className={styles.introTextContainer}>
        <h2 className={classNames([styles.introText, styles.darkText])}>
          TỐI ƯU VẬN HÀNH
        </h2>
        <h2 className={classNames([styles.introText, styles.darkText])}>
          TỐI ĐA HIỆU QUẢ
        </h2>
        <p className={classNames([styles.introTextDesc, styles.darkText])}>
          Giải pháp quản lý vận hành phòng máy chuyên nghiệp
        </p>
        <button
          className={styles.trialCtaBtn}
          onClick={() => navigate('/lien-he')}
        >
          DÙNG THỬ MIỄN PHÍ
        </button>
      </div>

      <div className={styles.introImgContainer}>
        <img src='/OBJECTS_edited.png' alt='' />
      </div>
    </section>
  );
}
