import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Ecosystem.module.css';
import { useScrollContext } from '../../context/ScrollContext/ScrollContextProvider';
import { products } from '../../utils/products';

export default function Ecosystem() {
  const { ecosystemRef } = useScrollContext();

  // * Hệ sinh thái sản phẩm chuyên biệt cho phòng game
  return (
    <section className={styles.ecosystemContainer} ref={ecosystemRef}>
      <div className={styles.ecosystemText}>
        <h2 className={styles.ecosystemTitle}>
          Hệ sinh thái sản phẩm chuyên biệt cho phòng game
        </h2>
        <p className={styles.ecosystemSubtitle}>
          Fusoft cung cấp giải pháp hoàn chỉnh cho việc kinh doanh phòng game
          với đầy đủ dãy sản phẩm chuyên biệt và dịch vụ chăm sóc khách hàng tối
          ưu
        </p>
      </div>
      <div className={styles.ecosystemProductsContainer}>
        <div className={styles.ecosystemProducts}>
          {products.map((item) => (
            <div key={item.id} className={styles.ecosystemProductItem}>
              <img src={item.image} alt='' />
              <div className={styles.productTextContainer}>
                <h3>{item.name}</h3>
                <p>{item.desc}</p>
                {item.linkToOutside ? (
                  <a className={styles.contactBtn} href={item.link}>
                    Xem chi tiết
                  </a>
                ) : (
                  <Link className={styles.contactBtn} to={item.link}>
                    Liên hệ
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
