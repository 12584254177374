import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import styles from './Footer.module.css';
import { useScrollContext } from '../../context/ScrollContext/ScrollContextProvider';

export default function Footer() {
  const { handleScroll, mainRef } = useScrollContext();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = async (elementRef, scrollToTop = false) => {
    const navigateToHomePage = async () => {
      if (location.pathname !== '/') {
        await navigate('/');
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    };

    const handleScrollAction = () => {
      if (scrollToTop) {
        window.scrollTo(0, 0);
      } else {
        handleScroll(elementRef);
      }
    };

    await navigateToHomePage();
    handleScrollAction();
  };

  return (
    <footer className={styles.footerContainer}>
      {/* Phần thông tin liên lạc (mail, sđt, 24/7) */}
      <div className={styles.contactInfo}>
        <div className={styles.contactInfoContainer}>
          {/* Item */}
          <div className={styles.contactInfoItem}>
            <div className={styles.info}>
              <img src='/phone.png' alt='' />
              <h3>ĐƯỜNG DÂY NÓNG</h3>
            </div>
            <p className={styles.infoDetail}>
              Điện thoại: <span>028 2200 0113</span>
            </p>
          </div>
          {/* Item */}
          <div className={styles.contactInfoItem}>
            <div className={styles.info}>
              <img src='/mail.png' alt='' />
              <h3>HỘP THƯ</h3>
            </div>
            <p className={styles.infoDetail}>
              <a href='mailto:info@fusoft.vn'>info@fusoft.vn</a>
            </p>
          </div>
          {/* Item */}
          <div className={styles.contactInfoItem}>
            <div className={styles.info}>
              <img src='/clock.png' alt='' />
              <h3>HỖ TRỢ TRỰC TUYẾN</h3>
            </div>
            <p className={styles.infoDetail}>07h - 22h hàng ngày</p>
          </div>
        </div>
      </div>

      {/* những đường link qua các trang khác */}
      <div className={styles.navigation}>
        <div className={styles.navigationContainer}>
          {/* Item */}
          <div className={styles.navItem}>
            <h3>ĐIỀU HƯỚNG</h3>
            <ul>
              <li>
                <span onClick={() => handleClick(mainRef, true)}>
                  Trang chủ
                </span>
              </li>
              <li>
                <Link to={'/chinh-sach-bao-mat'}>Chính sách bảo mật</Link>
              </li>
            </ul>
          </div>
          {/* Item */}
          <div className={styles.navItem}>
            <h3>SẢN PHẨM & DỊCH VỤ</h3>
            <ul>
              <li>Fusoft</li>
              <li>Fumeli</li>
              <li>Fnet</li>
              <li>FFood</li>
              <li>OEG - Esport</li>
              <li>Online Support</li>
            </ul>
          </div>
          {/* Item */}
          <div className={styles.navItem}>
            <h3>CÔNG TY CỔ PHẦN CÔNG NGHỆ FUS</h3>
            <p>
              Tầng 5, 144-146 Nguyễn Thái Bình, Phường Nguyễn Thái Bình, Quận 1,
              Tp. Hồ Chí Minh
            </p>
          </div>
        </div>
      </div>

      {/* copyright */}
      <div className={styles.copyright}>
        <p>© 2023 by Fusoft</p>
      </div>
    </footer>
  );
}
