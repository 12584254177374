export const feedbacks = [
  {
    id: 1,
    image: '/anh_phong.jpg',
    text: 'Anh Phong - quản lý Spartacus Gaming Center',
    desc: 'Với Spartacus Gaming Center, Fusoft là đơn vị đối tác tin cậy, chúng tôi đang sử dụng phần mềm của Fusoft cho tất cả các cơ sở phòng máy của Spartacus. Phần mềm update của Fusoft chất lượng tốt, đội kỹ thuật và phát triển sản phẩm nhiệt tình, chuyên nghiệp. Tôi hoàn toàn hài lòng khi làm việc cùng một đội ngũ chuyên nghiệp như vậy. Chúc cho Fusoft không ngừng phát triển mang đến sản phẩm và dịch vụ chất lượng hơn nữa.',
  },
  {
    id: 2,
    image: '/anh_duy.jpg',
    text: 'Anh Duy - quản lý SBN Premium Quy Nhơn.',
    desc: 'SBN premium là chuỗi phòng nét lớn tại TP Quy Nhơn được thành lập từ 2017 đến nay. Chúng tôi đã dùng qua rất nhiều phần mềm quản lý & update-game trên thị trường và Fusoft là một phần mềm tốt nhất đến hiện tại. Fusoft có thế mạnh là có công ty đại lý tại tỉnh giúp hỗ trợ kỹ thuật nên tôi thật sự an tâm kinh doanh. Tôi cũng hy vọng Fusoft sẽ phát triển và đảm bảo các dịch vụ hỗ trợ thật tốt hơn nữa để chúng tôi cùng đồng hành.',
  },
  {
    id: 3,
    image: '/anh_hoang.jpg',
    text: 'Anh Hoàng - HAT Cyber Bình Dương',
    desc: 'Tôi bắt đầu sử dụng phần mềm cập nhật game của Fusoft ngay sau từ khi ra mắt sản phẩm của công ty này cho cyber của tôi ở Bình Dương. Đây là một sản phẩm được đầu tư bài bản do các kỹ sư phần mềm Việt Nam xây dựng nên tôi rất yên tâm. Giao diện đơn giản,thân thiện giúp sản phẩm có chất lượng tốt và đặt biệt các anh kỹ thuật viên rất nhiệt trình trong việc chăm sóc khách hàng sau khi cài đặt sản phẩm. Tôi vẫn tiếp tục tin tưởng các sản phẩm của công ty Fusoft và chúc công ty phát triển tốt. Cảm ơn!',
  },
  {
    id: 4,
    image: '/anh_tam.jpg',
    text: 'Anh Tâm - quản lý Cyber Legend',
    desc: 'Tôi biết đến Fusoft từ những người bạn đã làm phòng game và giới thiệu một giải pháp quản lý - cập nhật game tối ưu cho việc quản lý chuỗi phòng máy. Hiện tại chuỗi của tôi có hơn 10 phòng tại TP HCM và đều sử dụng giải pháp phần mềm của Fusoft. Tôi thật sự tin cậy Fusoft vì chất lượng sản phẩm và hỗ trợ khách hàng của công ty và các anh kỹ thuật viên luôn lắng nghe và cập nhật những tính năng mới cho sản phẩm để phù hợp hơn với khách hàng. Chúc công ty Fusoft càng phát triển thành công hơn nữa.',
  },
];
