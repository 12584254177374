import React from "react";

import styles from './WhyFusoft.module.css'
import { useScrollContext } from "../../context/ScrollContext/ScrollContextProvider";
import { reasons } from "../../utils/reasons";

export default function WhyFusoft() {
  const { whyChooseUsRef } = useScrollContext();

  // * Lý do lựa chọn FuSoft
  return (
    <section className={styles.whyFuSoftContainer} ref={whyChooseUsRef}>
      <div className={styles.reasonTitle}>
        <span>Lý do lựa chọn Fusoft</span>
        <h2>Dịch vụ của Fusoft có gì tốt?</h2>
      </div>
      <div className={styles.reasons}>
        <div className={styles.reasonsContainer}>
          {reasons.map((item, index) => (
            <div key={item.id} className={styles.reasonItem}>
              <img src={item.image} alt='' />
              <div className={styles.reasonText}>
                <span>{`${index + 1}. ${item.text}`}</span>
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
