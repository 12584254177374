export const reasons = [
  {
    id: 1,
    image: '/speed.png',
    text: 'Tốc độ nhanh',
    desc: 'Update tự động, khả năng cập nhật dữ liệu nhanh-mạnh là các yếu tố quan trọng mang lại cảm nhận hoàn hảo cho người dùng.',
  },
  {
    id: 2,
    image: '/ui.png',
    text: 'Tối ưu hóa giao diện',
    desc: 'Giao diện thân thiện, dễ dàng sử dụng, bạn có thể thao tác mà không gặp phải bất kỳ khó khăn nào.',
  },
  {
    id: 3,
    image: '/security.png',
    text: 'Tính bảo mật cao',
    desc: 'Đảm bảo bảo mật cao bằng các giải pháp an ninh tiên tiến, khả năng lưu trữ dữ liệu lớn, cơ chế sao lưu, khôi phục dữ liệu.',
  },
  {
    id: 4,
    image: '/support_team.png',
    text: 'Đội ngũ kỹ thuật',
    desc: 'Đội ngũ kỹ thuật viên dày dặn kinh nghiệm, luôn sẵn sàng xử lý nhanh chóng, chính xác mọi yêu cầu hỗ trợ bất kể ngày nghỉ hay lễ tết.',
  },
  {
    id: 5,
    image: '/support_channel.png',
    text: 'Đa kênh hỗ trợ',
    desc: 'Sẵn sàng hỗ trợ khách hàng 24/7 đảm bảo rằng mọi vấn đề của khách hàng được giải quyết kịp thời và hiệu quả.',
  },
  {
    id: 6,
    image: '/promotion.png',
    text: 'Chương trình ưu đãi CSKH',
    desc: 'Fusoft thường xuyên có nhiều chương trình ưu đãi hấp dẫn, liên kết chăm sóc khách hàng thông qua cổng kết nối khách hàng, hội viên.',
  },
];