import { Route, Routes } from 'react-router-dom';

import { Contact, Home, Policy } from './pages';
import { Footer, Navbar, Popup } from './components';

export default function App() {
  return (
    <>
      <Navbar />
      <Popup />
      <div style={{ marginTop: '100px' }}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/lien-he' element={<Contact />} />
          <Route path='/chinh-sach-bao-mat' element={<Policy />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}
