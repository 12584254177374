import React from "react";

import styles from './ScrollArrow.module.css'
import { useScrollContext } from "../../context/ScrollContext/ScrollContextProvider";

export default function ScrollArrow() {
  const { handleScroll, aboutUsRef } = useScrollContext();

  // * Mũi tên tròn nhỏ phía dưới (click vào thì scroll xuống phần "Về FuSoft")
  return (
    <section
      className={styles.scrollDownArrowContainer}
      onClick={() => handleScroll(aboutUsRef)}
    >
      <div className={styles.arrowContainer}>
        <img src="/down.png" alt="" />
      </div>
    </section>
  );
}
