import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import axios from 'axios';
import PhoneNumber from 'libphonenumber-js';

import styles from './Contact.module.css';
import { PageTransition, ErrorMsg } from '../../components';

export default function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const isValidPhoneNumber = (value) => {
    try {
      const phoneNumber = PhoneNumber(value, 'VN');
      return phoneNumber.isValid();
    } catch (error) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('/action/lienhe', data, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        toast.success('Gửi liên hệ thành công');
        reset();
      } else {
        toast.error('Gửi liên hệ thất bại');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  document.title = 'Liên hệ | Fusoft.vn';

  return (
    <PageTransition>
      <div className={styles.contactContainer}>
        <h3 className={styles.title}>Liên hệ đến Fusoft</h3>
        <div className={styles.formContainer}>
          {/* Contact information */}
          <div className={styles.contactInfo}>
            <h3 className={styles.info}>Thông tin liên hệ</h3>
            <span className={styles.companyName}>
              CÔNG TY CỔ PHẦN CÔNG NGHỆ FUS
            </span>
            <p className={styles.address}>
              Tầng 5, 144-146 Nguyễn Thái Bình, Phường Nguyễn Thái Bình, Quận 1,
              Tp. Hồ Chí Minh
            </p>
            <span className={styles.hotline}>Hotline: 028.2200.0113</span>
            <span className={styles.email}>
              Email: <a href='mailto:info@fusoft.vn'>info@fusoft.vn</a>
            </span>
          </div>

          {/* Lead form */}
          <form className={styles.leadForm} onSubmit={handleSubmit(onSubmit)}>
            {/* Họ và tên */}
            <div className={styles.formGroup}>
              <label htmlFor='fullName'>
                Họ tên <span className={styles.required}>*</span>
              </label>
              <input
                placeholder='Nhập họ và tên'
                {...register('fullName', { required: true, maxLength: 30 })}
                aria-invalid={errors.fullName ? 'true' : 'false'}
                className={errors.fullName ? styles.requiredField : ''}
              />
              {errors.fullName?.type === 'required' && (
                <ErrorMsg msg='Vui lòng nhập họ tên' />
              )}
            </div>

            {/* Số điện thoại */}
            <div className={styles.formGroup}>
              <label htmlFor='phone'>
                Số điện thoại <span className={styles.required}>*</span>
              </label>
              <input
                type='phone'
                placeholder='Nhập số điện thoại'
                {...register('phone', {
                  required: true,
                  maxLength: 11,
                  validate: isValidPhoneNumber,
                })}
                className={errors.phone ? styles.requiredField : ''}
              />
              {errors.phone?.type === 'required' && (
                <ErrorMsg msg='Vui lòng nhập số điện thoại' />
              )}
              {errors.phone?.type === 'maxLength' && (
                <ErrorMsg msg='Số điện thoại không hợp lệ' />
              )}
              {errors.phone?.type === 'pattern' && (
                <ErrorMsg msg='Số điện thoại không hợp lệ' />
              )}
            </div>

            {/* Email */}
            <div className={styles.formGroup}>
              <label htmlFor='email'>
                Email <span className={styles.required}>*</span>
              </label>
              <input
                type='text'
                placeholder='Nhập email'
                {...register('email', {
                  required: true,
                  maxLength: 40,
                  pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                })}
                className={errors.email ? styles.requiredField : ''}
              />
              {errors.email?.type === 'required' && (
                <ErrorMsg msg='Vui lòng nhập email' />
              )}
              {errors.email?.type === 'maxLength' && (
                <ErrorMsg msg='Email không hợp lệ' />
              )}
              {errors.email?.type === 'pattern' && (
                <ErrorMsg msg='Email không hợp lệ' />
              )}
            </div>

            {/* Tiêu đề */}
            <div className={styles.formGroup}>
              <label htmlFor='title'>
                Tiêu đề <span className={styles.required}>*</span>
              </label>
              <input
                type='text'
                placeholder='Dịch vụ cần tư vấn'
                {...register('title', { required: true, maxLength: 50 })}
                className={errors.title ? styles.requiredField : ''}
              />
              {errors.title?.type === 'required' && (
                <ErrorMsg msg='Vui lòng nhập tiêu đề' />
              )}
            </div>

            {/* Nội dung */}
            <div className={styles.formGroup}>
              <label htmlFor='content'>
                Nội dung <span className={styles.required}>*</span>
              </label>
              <textarea
                type='text'
                placeholder='Chi tiết'
                {...register('content', { required: true })}
                className={errors.content ? styles.requiredField : ''}
              />
              {errors.content?.type === 'required' && (
                <ErrorMsg msg='Vui lòng nhập nội dung' />
              )}
            </div>

            <button type='submit' className={styles.submitBtn}>
              Gởi liên hệ
            </button>
          </form>
        </div>
        <div className={styles.mapContainer}>
          <h3>Văn phòng Fusoft</h3>
          <span>
            Tầng 5, tòa nhà 144-146 Nguyễn Thái Bình, Phường Nguyễn Thái Bình,
            Quận 1, Tp. Hồ Chí Minh
          </span>

          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.563398643643!2d106.69562037480468!3d10.768092789380205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3fdcdbbcab%3A0xc15e4bcaf5100bfc!2zMTQ0IMSQLiBOZ3V54buFbiBUaMOhaSBCw6xuaCwgUGjGsOG7nW5nIE5ndXnhu4VuIFRow6FpIELDrG5oLCBRdeG6rW4gMSwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1705388274781!5m2!1sen!2s'
            className={styles.googleMap}
            referrerPolicy='no-referrer-when-downgrade'
          />
        </div>
      </div>
    </PageTransition>
  );
}
