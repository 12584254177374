import { createContext, useContext, useRef } from 'react';

export const ScrollContext = createContext();

export const ScrollContextProvider = ({ children }) => {
  // Sections to scroll
  const mainRef = useRef();
  const aboutUsRef = useRef();
  const ecosystemRef = useRef();
  const whyChooseUsRef = useRef();
  const feedbacksRef = useRef();

  const handleScroll = (elementRef) => {
    const element = elementRef.current;
    const computedStyle = window.getComputedStyle(element);
    const paddingTop = parseInt(computedStyle.paddingTop);
    const paddingBottom = parseInt(computedStyle.paddingBottom);

    window.scrollTo({
      behavior:'smooth',
      top: element.offsetTop - paddingTop - paddingBottom,
    });
  };

  return (
    <ScrollContext.Provider
      value={{
        handleScroll,
        mainRef,
        aboutUsRef,
        ecosystemRef,
        whyChooseUsRef,
        feedbacksRef,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export const useScrollContext = () => {
  return useContext(ScrollContext);
};
