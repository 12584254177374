import React from "react";

import styles from './Feedbacks.module.css';
import { useScrollContext } from "../../context/ScrollContext/ScrollContextProvider";
import { feedbacks } from "../../utils/feedbacks";

export default function Feedbacks() {
  const { feedbacksRef } = useScrollContext();

  // * Feedback của 4 khách hàng
  return (
    <section className={styles.customerFeedbacks} ref={feedbacksRef}>
      <div className={styles.customerFeedbacksContainer}>
        {/* Feedback item goes here */}
        {feedbacks.map((item) => (
          <div key={item.id} className={styles.feedbackItem}>
            <img src={item.image} alt={item.text} />
            <span>{item.text}</span>
            <p>{item.desc}</p>
            <div className={styles.feedbackRating}>
              <img src='/star.png' alt='' />
              <img src='/star.png' alt='' />
              <img src='/star.png' alt='' />
              <img src='/star.png' alt='' />
              <img src='/star.png' alt='' />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
